<template>
  <div id="app">
    <!-- Header导航栏部分 -->
    <header class="header">
      <HeaderBar :index="'/submit'" />
    </header>

    <!-- Banner部分 -->
    <section class="banner-container">
      <div class="rectangle"></div>
      <span class="banner-text">{{ $t("submitYourIdea") }}</span>
    </section>

    <!-- Form部分 -->
    <section class="form-container">
      <!-- Title -->
      <div style="position: relative" class="input-container">
        <el-input v-model="form_title" class="title-container" style="--el-color-primary: #f36633">
        </el-input>
        <div class="container-title">
          <div style="background-color: white">
            <span class="required-symbol">&nbsp;&nbsp;* </span><span>{{ $t("title") }}&nbsp;&nbsp;</span>
          </div>
        </div>
      </div>
      <!-- Banner Img-->
      <div class="bannerimg-container" :style="{
        'justify-content': bannerImgUrl ? 'start' : 'center',
      }">
        <img :src="bannerImgUrl + '?' + getSasTokenFromCache()" v-if="bannerImgUrl" class="banner-img" />
        <div>
          <div class="bannerimg-tips" v-html="$t('imgCondition')"></div>
          <el-upload :action="uploadFileUrl" :data="BannerForm" :before-upload="beforeUpload" :accept="acceptTypes"
            :file-size-limit="fileSizeLimit" :on-success="handleBannerUploadSuccess" :show-file-list="false"
            :headers="{ Authorization: `Bearer ` + getUserInfo()!.tokenResult!.access_token }"
            style="--el-color-primary: #f36633">
            <el-button class="bannerimg-btn" color="#f6f6f6" style="
                --el-button-hover-border-color: #c9c9cf;
                --el-button-active-border-color: #c9c9cf;
              ">{{
                bannerImgUrl ? $t("chooseImgAgain") : $t("chooseImg")
              }}</el-button>
          </el-upload>
        </div>
      </div>
      <!-- Rich Text Input -->
      <div class="custom-quilleditor" style="position: relative">
        <div class="container-title">
          <div style="background-color: white">
            <span class="required-symbol">&nbsp;&nbsp;* </span><span>{{ $t("description") }}&nbsp;&nbsp;</span>
          </div>
        </div>
        <QuillEditor theme="snow" style="width: 100%" v-model:content="form_description" contentType="html" />
      </div>
      <!-- Category -->
      <div class="category-container">
        <div class="container-title">
          <div style="background-color: white">
            <span class="required-symbol">&nbsp;&nbsp;* </span><span>{{ $t("category") }}&nbsp;&nbsp;</span>
          </div>
        </div>
        <el-select v-model="categoryId" placeholder="Select" size="large" style="width: 80%;margin-top: 30px">
          <el-option v-for="item in categoryOptions" :key="item.Category.CategoryId" :label="item.Category.CategoryName"
            :value="item.Category.CategoryId" style="--el-color-primary: #f36633" />
        </el-select>
      </div>
      <!-- Tag -->
      <div class="tag-container">
        <div class="container-title">
          <div style="background-color: white">
            <span>&nbsp;&nbsp;{{ $t("tag") }}&nbsp;&nbsp;</span>
          </div>
        </div>
        <el-select-v2 v-model="selectedTag" :options="tagOptions" :placeholder="$t('selectMsg')" size="large"
          style="width:80%;margin-top:30px;--el-color-primary: #f36633; vertical-align: middle" allow-create filterable
          multiple clearable remote :remote-method="GetTagList" @change="handleTagChange" collapse-tags
          max-collapse-tags="10" multiple-limit="5" />
        <div style="margin-top:10px;margin-bottom:10px;color:#868693">{{ $t('tagLimit') }}</div>
      </div>
      <!-- Attachments -->
      <div class="attachment-container">
        <div class="container-title">
          <div style="background-color: white">
            <span>&nbsp;&nbsp;{{ $t("attachments") }}&nbsp;&nbsp;</span>
          </div>
        </div>
        <div class="elupload-container">
          <el-upload v-model:file-list="attFileList" drag multiple :action="uploadFileUrl"
            style="--el-color-primary: #f36633" :data="AttForm" :before-remove="beforeRemove"
            :headers="{ Authorization: `Bearer ` + getUserInfo()!.tokenResult!.access_token }">
            <div class="el-upload__text">{{ isMobile ? $t("mobileUploadMsg") : $t("drag") }}</div>
          </el-upload>
        </div>
      </div>
      <div class="warning-container">
        <el-alert :title="$t('submit_warning')" type="warning" :closable="false" show-icon />
      </div>
      <!-- Button Group -->
      <div class="btn-row">
        <el-button class="submit-btn" @click="submitIdea"
          :disabled="form_title.trim() == '' || isEmptyHtml(form_description) || categoryId == ''">{{ $t("submitIdea")
          }}</el-button>
        <el-button class="cancel-btn" color="white
      " @click="resetSubmit">{{ $t("reset") }}</el-button>
      </div>
    </section>
    <div style="height: 20vh"></div>
    <!-- footer -->
    <section class="footer">{{ $t("aboutGSKMsg") }}</section>
    <!-- footer bar -->
    <section class="footer_bar">
      <FooterBar :activeItem="2"></FooterBar>
    </section>
  </div>
</template>

<style>
.el-select-dropdown__item.selected {
  color: #f36633 !important;
  --el-color-primary: #f36633 !important;
}
</style>

<style scoped>
.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(5px);
  z-index: 9;
}

.banner-container {
  position: relative;
  background-size: cover;
  background-position: center;
  background-image: url("@/assets/images/browse/TOP.png");
  width: 100%;
  height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 8;
}

.rectangle {
  position: absolute;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: 5rem;
  background-image: url("@/assets/images/index/Rectangle.png");
  background-position: center;
  background-size: cover;
  align-items: end;
}

.banner-text {
  color: #3c3c43;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.form-container {
  text-align: center;
  margin-top: 4vh;
}


:deep(.el-select__wrapper.is-focused) {
  box-shadow: 0 0 0 1px #f36633;
}

.title-container {
  width: 50vw;
  height: 4rem;
}

:deep(.el-input__wrapper.is-focus) {
  border: 1px solid #3c3c43;
  box-shadow: none;
}

.title-text {
  color: #484851;
  text-align: center;
  font-family: GSK Precision;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  text-align: center;
}

.required-symbol {
  color: red;
  margin-right: 5px;
}

.bannerimg-container {
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 50vw;
  min-height: 12rem;
  margin-top: 2.5rem;
  border-radius: 8px;
  border: 1px dashed #c9c9cf;
  background: #f6f6f6;
  padding-left: 32px;
  padding-right: 32px;
  box-sizing: border-box;
}

.bannerimg-tips {
  margin-left: 1.5rem;
  text-align: left;
  color: #868693;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 162.5%;
}

.bannerimg-btn {
  z-index: 999;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  margin-top: 0.5rem;
  height: 2rem;
  padding: 0px 1.25rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #c9c9cf;
  font-family: GSK Precision;
  color: #484851;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
}

.custom-quilleditor {
  height: 20vh;
  width: 50vw;
  margin: 4rem auto;
}

:deep(.ql-toolbar.ql-snow) {
  padding-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
}

.container-title {
  position: absolute;
  top: -0.3rem;
  /* right: 35%;
  width: 30%; */
  left: 50%;
  transform: translate(-50%, -50%);
  height: 20px;
  text-align: center;
  font-size: 1.25rem;
  z-index: 999;
  color: #484851;
  font-style: normal;
  font-weight: 400;
}

.attachment-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4rem;
  width: 50vw;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #c9c9cf;
  min-height: 8rem;
}

.tag-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4rem;
  width: 50vw;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #c9c9cf;
  min-height: 6rem;
}

.category-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 7rem;
  width: 50vw;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #c9c9cf;
  min-height: 6rem;
}

.warning-container {
  position: relative;
  width: 50%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: -60px;
  text-align: left;
}

.el-upload-dragger .el-upload__text {
  color: #868693;
  text-align: center;
  /* 16px */
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
}

.footer {
  height: 120px;
  width: 100%;
  background: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #484851;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.elupload-container {
  margin-top: 2rem;
  margin-bottom: 1rem;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

:deep(.el-upload-dragger) {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 4.5rem;
  border: 1px dashed #c9c9cf;
  background: #f6f6f6;
  border-radius: 8px;
}

:deep(.title-container .el-input__inner) {
  margin-top: 0.5rem;
  color: #484851;
  text-align: center;
  font-family: GSK Precision;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 1000;
}

.btn-row {
  width: 100%;
  display: flex;
  gap: 0.75rem;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
  height: 3rem;
}

.submit-btn {
  color: white;
  margin-top: 50px;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  height: 50px;
  padding: 0px 32px;
  border-radius: 10px;
  font-family: GSK Precision;
  --el-button-bg-color: #f36633 !important;
  --el-button-border-color: #f36633 !important;
  --el-button-hover-bg-color: #376f7b !important;
  --el-button-hover-text-color: white !important;
  --el-button-hover-border-color: #376f7b !important;
  --el-button-active-bg-color: #376f7b !important;
  --el-button-active-border-color: #376f7b !important;
}

.el-button.is-disabled {
  color: var(--el-button-disabled-text-color) !important;
}

.cancel-btn {
  margin-top: 50px;
  color: #484851;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  height: 50px;
  padding: 0px 32px;
  border-radius: 10px;
  font-family: GSK Precision;
  --el-button-border-color: #c9c9cf !important;
  --el-button-hover-border-color: #c9c9cf !important;
  --el-button-active-border-color: #c9c9cf !important;
  --el-button-hover-text-color: #484851 !important;
  --el-button-active-text-color: #484851 !important;
}

.banner-img {
  width: 128px;
  height: 96px;
  border-radius: 8px;
}

.footer_bar {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .title-container {
    width: 80vw;
  }

  .bannerimg-container {
    width: 80vw;
  }

  .custom-quilleditor {
    width: 80vw;
  }

  .attachment-container {
    width: 80vw;
  }

  .tag-container {
    width: 80vw;
  }

  .category-container {
    width: 80vw;
  }

  .footer {
    padding-bottom: 5rem;
  }

  .footer_bar {
    display: flex;
    position: fixed;
    bottom: 0;
    z-index: 9999999;
  }
}
</style>

<script lang="ts">
import HeaderBar from "@/components/HeaderBar.vue";
import FooterBar from "@/components/FooterBar.vue";
import { ref, inject, onMounted, watchEffect } from "vue";
import { ElLoading, ElMessage } from "element-plus";
import { AxiosInstance } from "axios";
import { useRouter } from "vue-router";
import { getUserInfo } from "@/state/UserInfo";
import { getSasTokenFromCache } from "@/state/getSasToken";
import type { UploadUserFile } from "element-plus";
import { useI18n } from "vue-i18n";


export default {
  components: {
    HeaderBar,
    FooterBar,
  },
  setup() {

    const { t } = useI18n();
    const form_title = ref(localStorage.getItem("form_title") || "");
    const form_description = ref(localStorage.getItem("form_description") || "<p></p>");
    const categoryId = ref(Number(localStorage.getItem("categoryId")) || "");
    const selectedTag = ref(JSON.parse(localStorage.getItem("selectedTag") || "[]"));

    onMounted(async () => {
      window.scrollTo(0, 0);
      isMobile.value = window.innerWidth < 1200;
      GetCategoryList();
      allTagOptions.value = await GetTagList();
      console.log("allTagOptions", allTagOptions.value)
    });

    const GetCategoryList = () => {
      axiosInstance.get("api/Category/GetCategoryList", { params: { state: 1 } }).then((res) => {
        categoryOptions.value = res.data.Data;
      });
    };

    const GetTagList = async (keywords?: string) => {
      const params = keywords ? { keywords } : {};
      await axiosInstance.get("api/Tag/GetTagList", { params }).then((res) => {
        tagOptions.value = res.data.Data.map((option: { Tag: { TagId: any; TagName: any; }; }) => ({
          value: option.Tag.TagId,
          label: option.Tag.TagName
        }));
      });
      return tagOptions.value;
    };


    const handleTagChange = (newValues: any[]) => {
      if (newValues.length > 5) {
        ElMessage.error(t('tagLimit'));
        return;
      }

      Tag.value = [];

      newValues.slice(0, 5).forEach(newValue => {
        if (typeof newValue === 'number') {
          const foundOption = allTagOptions.value.find(option => option.value === newValue);
          if (foundOption) {
            Tag.value.push({
              TagId: newValue,
              TagName: foundOption.label
            });
          } else {
            Tag.value.push({
              TagId: newValue,
              TagName: 'Unknown'
            });
          }
        } else {
          const existingOption = allTagOptions.value.find(option => option.label === newValue);
          if (existingOption) {
            Tag.value.push({
              TagId: existingOption.value,
              TagName: existingOption.label
            });
          } else {
            Tag.value.push({
              TagId: null,
              TagName: newValue
            });
          }
        }
      });
    };

    interface CategoryOption {
      Category: {
        CategoryId: number;
        CategoryName: string;
      };
    }
    interface TagOption {
      TagId: number | null;
      TagName: string;
    }
    interface Option {
      value: number;
      label: string;
    }

    const categoryOptions = ref<CategoryOption[]>([])
    const tagOptions = ref<Option[]>([])
    const allTagOptions = ref<Option[]>([])
    const Tag = ref<TagOption[]>([])

    const attFileList = ref<UploadUserFile[]>(localStorage.getItem("attFileList") ? JSON.parse(localStorage.getItem("attFileList") as string) : []);
    interface UploadFileResponse {
      AttId: number;
      AttName: string;
      AttPath: string;
      BlobFileName: string;
      ContainerName: string;
      CreatedBy: number;
      IdeaID: number;
      Type: number;
    }
    interface ResponseData {
      Data: UploadFileResponse[];
    }

    const isMobile = ref(false);
    const generateGuid = () => {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    };
    const router = useRouter();
    const axiosInstance = inject("axiosInstance") as AxiosInstance;
    const uploadFileUrl =
      process.env.VUE_APP_BASE_URL + "api/FileHandler/UploadFile";
    const bannerImgUrl = ref(localStorage.getItem("bannerImgUrl") || "");
    const acceptTypes = "image/jpeg,image/png,image/gif";
    const fileSizeLimit = 5 * 1024 * 1024;
    const AttGuid = localStorage.getItem("AttGuid") || generateGuid();
    // const AttGuid = generateGuid();
    const AttForm = ref({
      IdeaId: 0,
      UserId: getUserInfo()!.graphUserInfo!.UserId,
      AttGuid: AttGuid,
    });
    const BannerForm = ref({
      IdeaId: 0,
      UserId: getUserInfo()!.graphUserInfo!.UserId,
      AttGuid: AttGuid,
      SceneType: 2,
    });
    const handleBannerUploadSuccess = (response: ResponseData) => {
      console.log(response);
      bannerImgUrl.value = response.Data[0].AttPath;
      console.log("上传成功", bannerImgUrl.value);
      // bannerImgUrl.value = response.Data[0].AttPath;
    };

    const beforeUpload = (file: File) => {
      const isAllowFileType =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/gif";
      const isLt500M = file.size / 1024 / 1024 < 500;

      if (!isAllowFileType) {
        ElMessage.error("只能上传 JPG、JPEG、PNG 或 GIF 格式的图片");
      }
      if (!isLt500M) {
        ElMessage.error("文件大小不能超过 500MB");
      }

      return isAllowFileType && isLt500M;
    };

    const beforeRemove = (file: File) => {
      return axiosInstance
        .request({
          url: "api/FileHandler/DeleteFile",
          data: {
            IdeaId: 0,
            AttId: 0,
            AttName: file.name,
            AttGuid: AttGuid,
          },
          method: "post",
        })
        .then((res) => {
          console.log("删除文件成功", res);
        });
    };
    const submitIdea = async () => {
      try {
        const res = await axiosInstance.request({
          url: "api/Idea/AddIdea",
          data: {
            title: window.btoa(unescape(encodeURIComponent(form_title.value.trim()))),
            description: window.btoa(unescape(encodeURIComponent(form_description.value))),
            // description: form_description.value.replace(/</g, '&lt;').replace(/>/g, '&gt;'),
            createdBy: getUserInfo()!.graphUserInfo!.UserId,
            state: 1,
            status: 1,
            owner: getUserInfo()!.graphUserInfo!.UserId,
            bannerImgUrl: bannerImgUrl.value.replace(/https:/g, 'schema:'),
            AttGuid: AttGuid,
            CategoryId: categoryId.value,
            Tag: Tag.value,
          },
          method: "post",
        });
        console.log("res", res);
        if (res && res.data) {
          ElMessage.success(t('submitSuccess'));
          form_title.value = "";
          form_description.value = "<p></p>";
          bannerImgUrl.value = "";
          attFileList.value = [];
          categoryId.value = '';
          selectedTag.value = [];
          router.push("/browse");
        } else {
          ElMessage.warning(t('submitFailed1'));
        }
      } catch (error) {
        console.info("error", error);
        ElMessage.warning(t('submitFailed2'));
      }
    };

    const isEmptyHtml = (html: string) => {
      const div = document.createElement("div");
      div.innerHTML = html;
      return div.textContent!.trim() === "";
    };

    const resetSubmit = () => {
      form_title.value = "";
      form_description.value = "<p></p>";
      bannerImgUrl.value = "";
      attFileList.value = [];
      categoryId.value = '';
      selectedTag.value = [];
      attFileList.value = [];
    };

    watchEffect(() => {
      localStorage.setItem("form_title", form_title.value);
      localStorage.setItem("form_description", form_description.value);
      localStorage.setItem("categoryId", categoryId.value.toString());
      localStorage.setItem("selectedTag", JSON.stringify(selectedTag.value));
      localStorage.setItem("bannerImgUrl", bannerImgUrl.value);
      localStorage.setItem("attFileList", JSON.stringify(attFileList.value));
      localStorage.setItem("AttGuid", AttGuid);
    })

    return {
      form_title,
      form_description,
      acceptTypes,
      fileSizeLimit,
      uploadFileUrl,
      bannerImgUrl,
      isMobile,
      attFileList,
      AttForm,
      BannerForm,
      isEmptyHtml,
      getSasTokenFromCache,
      handleBannerUploadSuccess,
      beforeUpload,
      beforeRemove,
      submitIdea,
      getUserInfo,
      resetSubmit,
      categoryId,
      categoryOptions,
      selectedTag,
      tagOptions,
      handleTagChange,
      GetTagList
    };
  },
};
</script>

<template>
  <div id="app">
    <!-- Header导航栏部分 -->
    <header class="header">
      <HeaderBar :index="'/browse'" />
    </header>
    <!-- Banner部分 -->
    <section class="banner-container">
      <div class="rectangle">
        <div class="rectangle-left">
          <span>{{ $t("showMyIdeas") }}</span>
          <el-switch v-model="isMyIdea" class="showmyidea-switch" size="large" @change="handleSwitchChange" />
        </div>
        <div class="rectangle-right">
          <el-input v-model="searchText" class="search-bar" size="large" :placeholder="$t('searchAnything')"
            :prefix-icon="Search" @input="handleTextInput" style="--el-color-primary: #f36633">
          </el-input>
        </div>
      </div>
      <div class="banner-text-container">
        <div class="banner-title">{{ $t("allIdeas") }}</div>
      </div>
    </section>

    <!-- 卡片 + 筛选栏 -->
    <section class="main-container">
      <IdeaFilter @filterUpdate="handleFilterUpdate" :initialStatusCode="initialStatusCode" style="min-width: 10rem"
        :key="IdeaFilterKey" id="IdeaFilter" />
      <div class="card-container" v-infinite-scroll="loadMoreIdea" infinite-scroll-disabled="loadMoreIdeaLoading">
        <div class="card-sub-container">
          <div class="card" v-for="(card, i) in ideaList1" :key="i" @click="navToDetail(card.Idea.IdeaId)">
            <div class="type-text" :style="`color: ${getStatusColor(card.Idea.Status)}`">
              <span>{{ getStatusText(card.Idea.Status) }}</span>
            </div>
            <div class="title-text">{{ card.Idea.Title }}</div>
            <div class="summary-text">
              {{ removeHtmlTags(card.Idea.Description) }}
            </div>

            <img :src="card.Idea.BannerImgUrl + '?' + getSasTokenFromCache()" style="width: 100%; padding-top: 12px"
              v-if="card.Idea.BannerImgUrl" />
            <div class="info-row">
              <div class="info-left">
                <el-avatar :src="'data:image/jpeg;base64,' + card.User.Avatar" :size="24"></el-avatar>
                <span style="margin-left: 4px">{{ card.User.UserName }}</span>
              </div>
              <PostActions :card="card" @operationCountChange="operationCountChange" />
            </div>
          </div>
        </div>
        <div class="card-sub-container">
          <div class="card" v-for="(card, i) in ideaList2" :key="i" @click="navToDetail(card.Idea.IdeaId)">
            <div class="type-text" :style="`color: ${getStatusColor(card.Idea.Status)}`">
              <span>{{ getStatusText(card.Idea.Status) }}</span>
            </div>
            <div class="title-text">{{ card.Idea.Title }}</div>
            <div class="summary-text">
              {{ removeHtmlTags(card.Idea.Description) }}
            </div>

            <img :src="card.Idea.BannerImgUrl + '?' + getSasTokenFromCache()" style="width: 100%; padding-top: 12px"
              v-if="card.Idea.BannerImgUrl" />
            <div class="info-row">
              <div class="info-left">
                <el-avatar :src="'data:image/jpeg;base64,' + card.User.Avatar" :size="24"></el-avatar>
                <span style="margin-left: 4px">{{ card.User.UserName }}</span>
              </div>
              <PostActions :card="card" @operationCountChange="operationCountChange" />
            </div>
          </div>
        </div>
        <div class="card-sub-container">
          <div class="card" v-for="(card, i) in ideaList3" :key="i" @click="navToDetail(card.Idea.IdeaId)">
            <div class="type-text" :style="`color: ${getStatusColor(card.Idea.Status)}`">
              <span>{{ getStatusText(card.Idea.Status) }}</span>
            </div>
            <div class="title-text">{{ card.Idea.Title }}</div>
            <div class="summary-text">
              {{ removeHtmlTags(card.Idea.Description) }}
            </div>

            <img :src="card.Idea.BannerImgUrl + '?' + getSasTokenFromCache()" style="width: 100%; padding-top: 12px"
              v-if="card.Idea.BannerImgUrl" />
            <div class="info-row">
              <div class="info-left">
                <el-avatar :src="'data:image/jpeg;base64,' + card.User.Avatar" :size="24"></el-avatar>
                <span style="margin-left: 4px">{{ card.User.UserName }}</span>
              </div>
              <PostActions :card="card" @operationCountChange="operationCountChange" />
            </div>
          </div>
        </div>
        <div class="card-sub-container">
          <div class="card" v-for="(card, i) in ideaList4" :key="i" @click="navToDetail(card.Idea.IdeaId)">
            <div class="type-text" :style="`color: ${getStatusColor(card.Idea.Status)}`">
              <span>{{ getStatusText(card.Idea.Status) }}</span>
            </div>
            <div class="title-text">{{ card.Idea.Title }}</div>
            <div class="summary-text">
              {{ removeHtmlTags(card.Idea.Description) }}
            </div>

            <img :src="card.Idea.BannerImgUrl + '?' + getSasTokenFromCache()" style="width: 100%; padding-top: 12px"
              v-if="card.Idea.BannerImgUrl" />
            <div class="info-row">
              <div class="info-left">
                <el-avatar :src="'data:image/jpeg;base64,' + card.User.Avatar" :size="24"></el-avatar>
                <span style="margin-left: 4px">{{ card.User.UserName }}</span>
              </div>
              <PostActions :card="card" @operationCountChange="operationCountChange" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Footer部分 -->
    <section class="footer">{{ $t("aboutGSKMsg") }}</section>
    <!-- footer bar -->
    <section class="footer_bar">
      <FooterBar :activeItem="3"></FooterBar>
    </section>

    <el-backtop :right="40" :bottom="100" />
  </div>
</template>

<style scoped>
.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
}

.banner-container {
  position: relative;
  background-size: cover;
  background-position: center;
  background-image: url("@/assets/images/browse/TOP.png");
  width: 100%;
  height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 8;
}

.rectangle {
  position: absolute;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: 5rem;
  background-image: url("@/assets/images/index/Rectangle.png");
  background-position: center;
  background-size: cover;
  align-items: end;
}

.banner-text-container {
  z-index: 999;
}

.banner-title {
  color: #3c3c43;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.rectangle-left {
  margin-left: 4rem;
  color: #3c3c43;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.rectangle-right {
  margin-right: 4rem;
}

.showmyidea-switch {
  margin-left: 25px;
  --el-switch-on-color: #f36633;
}

.search-bar {
  width: 15rem;
}

:deep(.el-input__wrapper) {
  border-radius: 44px;
}

.main-container {
  margin-top: 48px;
  padding-left: 4rem;
  padding-right: 4rem;
  display: flex;
  gap: 2rem;
  padding-bottom: 120px;
}

.card-container {
  max-width: 90%;
  column-count: 4;
  column-gap: 2rem;
}

.card-sub-container {
  width: 100%;
  column-count: 1;
  break-inside: avoid;
}

.card {
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #c9c9cf;
  padding: 12px 0px;
  margin-bottom: 32px;
  width: 100%;
}

.type-text {
  padding: 0px 12px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 150% */
}

.title-text {
  overflow-wrap: break-word;
  padding: 8px 12px 0px 12px;
  color: #3c3c43;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  /* 125% */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.summary-text {
  overflow-wrap: break-word;
  padding: 8px 12px 0 12px;
  color: #484851;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  /* 150% */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.card img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  /* 这将保持图片的原始比例 */
}

.info-row {
  display: flex;
  padding: 12px 12px 0px 12px;
  justify-content: space-between;
  align-items: center;
}

.info-left {
  display: flex;
  color: #868693;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}

:deep(.el-collapse) {
  border-top: none;
  border-bottom: none;
}

.el-avatar {
  border: 2px solid rgba(174, 174, 183, 0.2);
}

.icon {
  width: 16px;
  height: 16px;
}

.footer {
  height: 120px;
  width: 100%;
  background: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #484851;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

:deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
  background-color: #f36633;
  border-color: #f36633;
}

.card:hover {
  box-shadow: 0px 2px 10px 0px rgba(60, 60, 67, 0.2);
}

.footer_bar {
  display: none;
}

@media only screen and (max-width: 1024px) {

  .main-container {
    margin-top: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    display: block;
  }

  .card-container {
    -moz-column-count: 1;
    column-count: 1;
    column-gap: 1rem;
    min-width: 100%;
    margin-top: 1rem;
  }

  .search-bar {
    width: 130px;
  }

  .rectangle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .rectangle-right {
    margin-right: 1rem;
  }

  .rectangle-left {
    margin-left: 1rem;
    font-size: 0.75rem;
  }

  .el-input__inner {
    font-size: 0.75rem !important;
  }

  .showmyidea-switch {
    margin-left: 0.5rem;
  }

  .title-text {
    font-size: 18px;
  }

  .type-text {
    font-size: 14px;
  }

  .info-left {
    font-size: 10px;
  }

  .el-avatar {
    flex-shrink: 0;
  }

  .footer {
    padding-bottom: 5rem;
  }

  .footer_bar {
    display: flex;
    position: fixed;
    bottom: 0;
    z-index: 9999999;
  }

  :deep(.el-input__inner::placeholder) {
    font-size: 10px;
  }
}
</style>

<script lang="ts">
import { ref, onMounted, inject } from "vue";
import { Search } from "@element-plus/icons-vue";
import { AxiosInstance } from "axios";
import { useRouter } from "vue-router";
import HeaderBar from "@/components/HeaderBar.vue";
import IdeaFilter from "@/components/IdeaFilter.vue";
import PostActions from "@/components/PostActions.vue";
import { getUserInfo } from "@/state/UserInfo";
import { removeHtmlTags } from "@/utils/removeHtmlTags";
import { getStatusText } from "@/utils/getStatusText";
import { getStatusColor } from "@/utils/getStatusColor";
import { getSasTokenFromCache } from "@/state/getSasToken";
import FooterBar from "@/components/FooterBar.vue";

export default {
  components: {
    HeaderBar,
    IdeaFilter,
    PostActions,
    FooterBar,
  },
  setup() {
    interface IdeaItem {
      Idea: Idea;
      IsCollect: boolean;
      IsLike: boolean;
      User: User;
      PageCount: number;
    }

    interface Idea {
      CollectCount: number;
      CommentCount: number;
      CreatedBy: number;
      CreatedOn: string;
      Description: string;
      IdeaId: number;
      LikeCount: number;
      ModifiedBy: number;
      ModifiedOn: string;
      Owner: number;
      PlaygroundCreatedBy: number;
      PlaygroundCreatedOn: string;
      PlaygroundModifiedBy: number;
      PlaygroundModifiedOn: string;
      State: number;
      Status: number;
      SubmittedOn: string;
      Title: string;
      BannerImgUrl: string;
    }

    interface User {
      Avatar: string;
      UserId: number;
      UserName: string;
    }

    const ideaList = ref<IdeaItem[]>([]);
    const ideaList1 = ref<IdeaItem[]>([]);
    const ideaList2 = ref<IdeaItem[]>([]);
    const ideaList3 = ref<IdeaItem[]>([]);
    const ideaList4 = ref<IdeaItem[]>([]);
    const axiosInstance = inject("axiosInstance") as AxiosInstance;
    const searchText = ref("");
    const isMyIdea = ref(false);
    const router = useRouter();
    const initialStatusCode = Number(
      router.currentRoute.value.query.StatusCode
    );

    const loadMoreIdeaLoading = ref(false);
    /* eslint-disable */
    const checkedOperations = ref<string[]>([]);
    const checkedStatus = ref<string[]>();
    const checkedOwner = ref<string[]>();
    const checkedCategory = ref<string[]>();
    const checkedTag = ref<string[]>();
    const PageSize = ref(12);
    const PageIndex = ref(1);

    onMounted(async () => {
      window.scrollTo(0, 0);
      if (initialStatusCode) {
        checkedStatus.value = [initialStatusCode.toString()];
      }
      await getIdeaList();
    });

    /**
     * Retrieves the list of ideas from the server and rearranges them into columns.
     * The resulting list is assigned to both the `ideaList` and `checkedIdeaList` variables.
     * @function
     * @name getIdeaList
     * @returns {void}
     */
    const getIdeaList = (init?: boolean) => {
      console.info("init", init)
      const requestData = {
        PageSize: PageSize.value,
        PageIndex: init == true ? 1 : PageIndex.value,
        UserId: getUserInfo()!.graphUserInfo!.UserId,
        OnlyMyIdea: isMyIdea.value,
        ...(checkedStatus.value ? { Statuses: checkedStatus.value } : {}),
        ...(checkedOwner.value ? { Owners: checkedOwner.value } : {}),
        ...(searchText.value.trim() ? { SearchMoreText: searchText.value.trim() } : {}),
        ...(checkedOperations.value
          ? { Operations: checkedOperations.value }
          : {}),
        ...(checkedCategory.value ? { Categories: checkedCategory.value } : {}),
        ...(checkedTag.value ? { Tags: checkedTag.value } : {}),
      };
      console.log("requestData", requestData);
      console.log("checkedTag", checkedTag.value)
      return axiosInstance
        .request({
          url: "api/Idea/GetIdeaList",
          data: requestData,
          method: "post",
        })
        .then((res) => {
          let data: IdeaItem[] = res.data.Data;
          // data.sort((a, b) => {
          //   return b.Idea.IdeaId - a.Idea.IdeaId;
          // });

          if (init == true) {
            ideaList.value = data;
          } else {
            ideaList.value.push(...data);
          }

          ideaList.value.sort((a, b) => {
            return b.Idea.IdeaId - a.Idea.IdeaId;
          });

          ideaList1.value = [];
          ideaList2.value = [];
          ideaList3.value = [];
          ideaList4.value = [];

          for (let i = 0; i < ideaList.value.length; i++) {
            if (i % 4 == 0) {
              ideaList1.value.push(ideaList.value[i]);
            } else if (i % 4 == 1) {
              ideaList2.value.push(ideaList.value[i]);
            } else if (i % 4 == 2) {
              ideaList3.value.push(ideaList.value[i]);
            } else if (i % 4 == 3) {
              ideaList4.value.push(ideaList.value[i]);
            }
          }
        });
    };
    const handleTextInput = () => {
      getIdeaList(true);
    };
    const navToDetail = (IdeaId: number) => {
      router.push({
        path: "/ideas/" + IdeaId,
      });
    };

    /**
     * Updates the checked operations, status, department, and owner filters based on the selected filter.
     * Calls the `checkedIdeaListChange` function to update the list of checked ideas based on the selected filters.
     * @function
     * @name handleFilterUpdate
     * @param {Object} filter - The selected filter object containing the checked operations, status, department, and owner filters.
     * @returns {void}
     */
    const handleFilterUpdate = (filter: any) => {
      // status filter
      checkedStatus.value = filter.checkedStatus;
      // owner filter
      checkedOwner.value = filter.checkedOwner;
      // operation filter
      checkedOperations.value = filter.checkedOperations;
      // category filter
      checkedCategory.value = filter.checkedCategory;
      // tag filter
      checkedTag.value = filter.checkedTag;
      getIdeaList(true);
    };

    const handleSwitchChange = () => {
      getIdeaList(true);
    };

    const loadMoreIdea = () => {
      if (loadMoreIdeaLoading.value) {
        return;
      } else {
        if (ideaList.value.length > 0 && PageIndex.value >= ideaList.value[0].PageCount) {
          return;
        } else {
          PageIndex.value = PageIndex.value + 1;
          getIdeaList();
          console.log(PageSize.value);
        }

      }

    };

    const IdeaFilterKey = ref(0);
    const operationCountChange = () => {
      IdeaFilterKey.value++;
      console.info("IdeaFilterKey", IdeaFilterKey.value);
      // setTimeout(() => {
      //   IdeaFilterKey.value++;
      //   getIdeaList();
      // }, 1000); // Delay of 1 second
    };

    return {
      searchText,
      isMyIdea,
      ideaList,
      ideaList1,
      ideaList2,
      ideaList3,
      ideaList4,
      initialStatusCode,
      IdeaFilterKey,
      getStatusColor,
      navToDetail,
      getStatusText,
      handleFilterUpdate,
      handleTextInput,
      handleSwitchChange,
      loadMoreIdea,
      removeHtmlTags,
      getSasTokenFromCache,
      operationCountChange,
      Search,
      loadMoreIdeaLoading
    };
  },
};
</script>

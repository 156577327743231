import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "all-page" }
const _hoisted_2 = { class: "main-container" }
const _hoisted_3 = { class: "card-container" }
const _hoisted_4 = { class: "title-bar" }
const _hoisted_5 = { class: "title-text" }
const _hoisted_6 = { style: {"display":"flex","gap":"2rem"} }
const _hoisted_7 = { style: {"display":"flex","align-items":"center","gap":"0.2rem","color":"#868693"} }
const _hoisted_8 = { style: {"color":"#868693"} }
const _hoisted_9 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "9",
  height: "9",
  viewBox: "0 0 8 9",
  fill: "none",
  style: {"margin-right":"6px"}
}
const _hoisted_10 = ["fill"]
const _hoisted_11 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "9",
  height: "9",
  viewBox: "0 0 8 9",
  fill: "none"
}
const _hoisted_12 = ["fill"]
const _hoisted_13 = { style: {"margin-left":"6px"} }
const _hoisted_14 = { style: {"color":"#868693"} }
const _hoisted_15 = { style: {"color":"#868693"} }
const _hoisted_16 = { class: "footer" }
const _hoisted_17 = { class: "dialog-header" }
const _hoisted_18 = { class: "userinfo-bar" }
const _hoisted_19 = { class: "userinfo" }
const _hoisted_20 = { style: {"margin-left":"1rem"} }
const _hoisted_21 = { class: "username" }
const _hoisted_22 = { class: "createtime" }
const _hoisted_23 = ["onClick"]
const _hoisted_24 = { class: "comment-content" }
const _hoisted_25 = { class: "root-comment" }
const _hoisted_26 = { class: "child-comment" }
const _hoisted_27 = {
  class: "userinfo-bar",
  style: {"padding":"0"}
}
const _hoisted_28 = { class: "userinfo" }
const _hoisted_29 = { style: {"margin-left":"1rem"} }
const _hoisted_30 = { class: "username" }
const _hoisted_31 = { class: "createtime" }
const _hoisted_32 = { class: "createtime" }
const _hoisted_33 = ["onClick"]
const _hoisted_34 = { style: {"margin-left":"3.5rem","margin-top":"1rem"} }
const _hoisted_35 = {
  key: 0,
  style: {"display":"flex","padding-top":"1rem","padding-bottom":"1rem"}
}
const _hoisted_36 = { style: {"width":"100%"} }
const _hoisted_37 = { style: {"display":"flex","margin-left":"1rem"} }
const _hoisted_38 = {
  key: 0,
  style: {"text-align":"center"}
}
const _hoisted_39 = { class: "footer-input" }
const _hoisted_40 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ManagementHeaderBar = _resolveComponent("ManagementHeaderBar")!
  const _component_ManagementIdeaFilter = _resolveComponent("ManagementIdeaFilter")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_CaretBottom = _resolveComponent("CaretBottom")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_badge = _resolveComponent("el-badge")!
  const _component_Delete = _resolveComponent("Delete")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_CaretRight = _resolveComponent("CaretRight")!
  const _component_Promotion = _resolveComponent("Promotion")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createVNode(_component_ManagementHeaderBar, { index: '/management' })
    ]),
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_ManagementIdeaFilter, {
        class: "custom-filter",
        onFilterUpdate: $setup.handleFilterUpdate
      }, null, 8, ["onFilterUpdate"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("ideasManagement")), 1),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_el_input, {
              modelValue: $setup.searchText,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.searchText) = $event)),
              placeholder: _ctx.$t('searchAnything'),
              "prefix-icon": $setup.Search,
              onInput: $setup.handleSearchTextInput,
              clearable: "true",
              class: "search-bar",
              style: {"--el-color-primary":"#f36633"}
            }, null, 8, ["modelValue", "placeholder", "prefix-icon", "onInput"]),
            _createVNode(_component_el_button, {
              class: "export-btn",
              onClick: $setup.exportExcel
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("exportExcel")), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]),
        _createVNode(_component_el_table, {
          data: $setup.tableData,
          style: {"margin-top":"1rem","padding-bottom":"6rem","--el-color-primary":"#f36633"},
          onSortChange: $setup.sortChange
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              prop: "Idea.Title",
              label: _ctx.$t('idea')
            }, null, 8, ["label"]),
            _createVNode(_component_el_table_column, {
              prop: "Idea.CreatedBy",
              label: _ctx.$t('CreatedBy')
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_el_avatar, {
                    src: 'data:image/jpeg;base64,' + $setup.findDataById(scope.row.Idea.IdeaId)!.User.Avatar,
                    size: 24,
                    class: "custom-avatar"
                  }, null, 8, ["src"]),
                  _createTextVNode(_toDisplayString($setup.findDataById(scope.row.Idea.IdeaId)!.User.UserName), 1)
                ])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_table_column, {
              props: "Category.CategoryName",
              label: _ctx.$t('category'),
              width: "120"
            }, {
              default: _withCtx((scope) => [
                _createVNode(_component_el_select, {
                  modelValue: $setup.findDataById(scope.row.Idea.IdeaId)!.Category.CategoryName,
                  "onUpdate:modelValue": ($event: any) => (($setup.findDataById(scope.row.Idea.IdeaId)!.Category.CategoryName) = $event),
                  disabled: "",
                  placeholder: "Select"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createBlock(_component_el_option, {
                      key: $setup.findDataById(scope.row.Idea.IdeaId)!.Category.CategoryName,
                      label: $setup.findDataById(scope.row.Idea.IdeaId)!.Category.CategoryName,
                      value: $setup.findDataById(scope.row.Idea.IdeaId)!.Category.CategoryName
                    }, null, 8, ["label", "value"]))
                  ]),
                  _: 2
                }, 1032, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_table_column, {
              prop: "Tag",
              label: _ctx.$t('tag')
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("div", _hoisted_8, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.findDataById(scope.row.Idea.IdeaId)!.Tag, (tag, index) => {
                    return (_openBlock(), _createBlock(_component_el_tag, {
                      key: index,
                      type: "info",
                      style: {"margin-right":"0.5rem","margin-bottom":"0.5rem"}
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(tag.TagName), 1)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_table_column, {
              prop: "Idea.Status",
              label: _ctx.$t('status'),
              width: "150"
            }, {
              default: _withCtx((scope) => [
                _createVNode(_component_el_dropdown, { onCommand: $setup.handleStatusChange }, {
                  dropdown: _withCtx(() => [
                    _createVNode(_component_el_dropdown_menu, null, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Array.from(
                      { length: 4 },
                      (v, i) => i + 1
                    ), (value, index) => {
                          return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                            key: index,
                            class: "status-dropdown-item",
                            style: _normalizeStyle({
                      color: $setup.getStatusColor(value),
                    }),
                            command: {
                      StatusCode: value,
                      IdeaId: $setup.findDataById(scope.row.Idea.IdeaId)!.Idea.IdeaId,
                      IdeaTitle: $setup.findDataById(scope.row.Idea.IdeaId)!.Idea
                        .Title,
                      IdeaOwnerId: $setup.findDataById(scope.row.Idea.IdeaId)!.Idea.Owner,
                      IdeaOrignStatusCode: $setup.findDataById(scope.row.Idea.IdeaId)!.Idea.Status
                    }
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(), _createElementBlock("svg", _hoisted_11, [
                                _createElementVNode("circle", {
                                  cx: "4",
                                  cy: "4.5",
                                  r: "4",
                                  fill: $setup.getStatusColor(value)
                                }, null, 8, _hoisted_12)
                              ])),
                              _createElementVNode("span", _hoisted_13, _toDisplayString($setup.getStatusText(value)), 1)
                            ]),
                            _: 2
                          }, 1032, ["style", "command"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      (_openBlock(), _createElementBlock("svg", _hoisted_9, [
                        _createElementVNode("circle", {
                          cx: "4",
                          cy: "4.5",
                          r: "4",
                          fill: $setup.getStatusColor(
                      $setup.findDataById(scope.row.Idea.IdeaId)!.Idea.Status
                    )
                      
                        }, null, 8, _hoisted_10)
                      ])),
                      _createElementVNode("span", {
                        style: _normalizeStyle({
                    color: $setup.getStatusColor(
                      $setup.findDataById(scope.row.Idea.IdeaId)!.Idea.Status
                    ),
                  })
                      }, _toDisplayString($setup.getStatusText(
                      $setup.findDataById(scope.row.Idea.IdeaId)!.Idea.Status
                    )), 5),
                      _createVNode(_component_el_icon, { style: {"margin-left":"6px","color":"#868693"} }, {
                        default: _withCtx(() => [
                          _createVNode(_component_CaretBottom)
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _: 2
                }, 1032, ["onCommand"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_table_column, {
              prop: "CommentCount",
              label: _ctx.$t('comments'),
              sortable: "custom"
            }, {
              default: _withCtx((scope) => [
                _createVNode(_component_el_badge, {
                  value: $setup.findDataById(scope.row.Idea.IdeaId)!.Idea.CommentCount,
                  onClick: ($event: any) => ($setup.showCommentDialog(scope.row.Idea.IdeaId))
                }, null, 8, ["value", "onClick"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_table_column, {
              prop: "CollectCount",
              label: _ctx.$t('Collects'),
              sortable: "custom"
            }, {
              default: _withCtx((scope) => [
                _createVNode(_component_el_badge, {
                  value: $setup.findDataById(scope.row.Idea.IdeaId)!.Idea.CollectCount
                }, null, 8, ["value"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_table_column, {
              prop: "LikeCount",
              label: _ctx.$t('Likes'),
              sortable: "custom"
            }, {
              default: _withCtx((scope) => [
                _createVNode(_component_el_badge, {
                  value: $setup.findDataById(scope.row.Idea.IdeaId)!.Idea.LikeCount
                }, null, 8, ["value"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_table_column, {
              prop: "Idea.CreatedOn",
              label: _ctx.$t('createTime')
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("span", _hoisted_14, _toDisplayString($setup.formatTimestamp(
                  $setup.findDataById(scope.row.Idea.IdeaId)!.Idea.CreatedOn
                )), 1)
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_table_column, {
              prop: "Idea.ModifiedOn",
              label: _ctx.$t('lastUpdate')
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("span", _hoisted_15, _toDisplayString($setup.formatTimestamp(
                  $setup.findDataById(scope.row.Idea.IdeaId)!.Idea.ModifiedOn
                )), 1)
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_table_column, {
              prop: "Action",
              label: _ctx.$t('action')
            }, {
              default: _withCtx((scope) => [
                _createVNode(_component_el_icon, {
                  onClick: () => $setup.handleRowDelete(scope.$index, $setup.findDataById(scope.row.Idea.IdeaId)!.Idea.IdeaId),
                  style: {"cursor":"pointer"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Delete)
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              _: 1
            }, 8, ["label"])
          ]),
          _: 1
        }, 8, ["data", "onSortChange"]),
        _createElementVNode("footer", _hoisted_16, [
          _createVNode(_component_el_pagination, {
            small: "",
            background: "",
            layout: "prev, pager, next",
            "page-count": $setup.totalPageCount,
            onCurrentChange: $setup.handleCurrentPageChange,
            style: {"--el-color-primary":"#f36633"}
          }, null, 8, ["page-count", "onCurrentChange"]),
          _createVNode(_component_el_select, {
            class: "select-number",
            modelValue: $setup.pageNum,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.pageNum) = $event)),
            onChange: $setup.handlePageNumChange
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList([10, 20, 30, 40, 50], (item) => {
                return _createVNode(_component_el_option, {
                  key: item,
                  label: item + '/page',
                  value: item,
                  style: {"--el-color-primary":"#f36633"}
                }, null, 8, ["label", "value"])
              }), 64))
            ]),
            _: 1
          }, 8, ["modelValue", "onChange"])
        ])
      ])
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: $setup.dialogTableVisible,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.dialogTableVisible) = $event)),
      "modal-append-to-body": false,
      "append-to-body": false,
      center: true
    }, {
      header: _withCtx(() => [
        _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t("commentsDetail")), 1)
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_39, [
          _createVNode(_component_el_input, {
            modelValue: $setup.commentContent,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.commentContent) = $event)),
            placeholder: _ctx.$t('replyMsg'),
            style: {"--el-color-primary":"#f36633"}
          }, {
            append: _withCtx(() => [
              _createElementVNode("div", {
                style: _normalizeStyle([{"color":"white","min-width":"3rem","cursor":"pointer","--el-color-primary":"#f36633"}, {
                  background: $setup.commentContent.trim().length > 0 ? '#F36633' : '#aeaeb7',
                }]),
                disabled: $setup.commentContent.trim().length === 0
              }, [
                _createVNode(_component_el_icon, {
                  onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.handleRootCommentSubmit($setup.currentIdeaId)))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Promotion)
                  ]),
                  _: 1
                })
              ], 12, _hoisted_40)
            ]),
            _: 1
          }, 8, ["modelValue", "placeholder"])
        ])
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.commentData, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "comment-item"
          }, [
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, [
                _createVNode(_component_el_avatar, {
                  src: 'data:image/jpeg;base64,' + item.User.Avatar,
                  size: 40
                }, null, 8, ["src"]),
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("div", _hoisted_21, _toDisplayString(item.User.UserName), 1),
                  _createElementVNode("div", _hoisted_22, _toDisplayString($setup.formatTimeAgo(item.Comment.CreatedOn)), 1)
                ])
              ]),
              _createElementVNode("span", {
                class: "reply-button",
                onClick: ($event: any) => ($setup.handleReplyClick(item.Comment.CommId))
              }, _toDisplayString(_ctx.$t("reply")), 9, _hoisted_23)
            ]),
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("div", _hoisted_25, _toDisplayString(item.Comment.Content), 1)
            ]),
            _createElementVNode("div", _hoisted_26, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.Reply, (reply, reply_index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "child-comment-content",
                  key: reply_index
                }, [
                  _createElementVNode("div", _hoisted_27, [
                    _createElementVNode("div", _hoisted_28, [
                      _createVNode(_component_el_avatar, {
                        src: 'data:image/jpeg;base64,' + reply.User.Avatar,
                        size: 40
                      }, null, 8, ["src"]),
                      _createElementVNode("div", _hoisted_29, [
                        _createElementVNode("div", _hoisted_30, [
                          _createTextVNode(_toDisplayString(reply.User.UserName) + " ", 1),
                          _createElementVNode("span", _hoisted_31, [
                            _createVNode(_component_el_icon, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_CaretRight)
                              ]),
                              _: 1
                            })
                          ]),
                          _createTextVNode(" " + _toDisplayString(reply.PareCommUser.UserName), 1)
                        ]),
                        _createElementVNode("div", _hoisted_32, _toDisplayString($setup.formatTimeAgo(reply.Comment.CreatedOn)), 1)
                      ])
                    ]),
                    _createElementVNode("span", {
                      class: "reply-button",
                      onClick: ($event: any) => (
                $setup.handleReplyClick(item.Comment.CommId, reply.Comment.CommId)
                )
                    }, _toDisplayString(_ctx.$t("reply")), 9, _hoisted_33)
                  ]),
                  _createElementVNode("div", _hoisted_34, _toDisplayString(reply.Comment.Content), 1)
                ]))
              }), 128)),
              (item.Reply && item.commentCard)
                ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                    _createVNode(_component_el_avatar, {
                      src: 'data:image/jpeg;base64,' + $setup.getUserInfo()!.graphUserInfo!.avatar,
                      size: 40
                    }, null, 8, ["src"]),
                    _createElementVNode("div", _hoisted_36, [
                      _createVNode(_component_el_input, {
                        modelValue: $setup.commentText,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.commentText) = $event)),
                        autosize: { minRows: 3 },
                        type: "textarea",
                        placeholder: "Please input",
                        style: {"margin-left":"1rem","--el-color-primary":"#f36633"}
                      }, null, 8, ["modelValue"]),
                      _createElementVNode("div", _hoisted_37, [
                        _createVNode(_component_el_button, {
                          class: "submit-btn",
                          disabled: $setup.commentText.trim().length === 0,
                          onClick: ($event: any) => (
                  $setup.handleChildCommentSubmit(
                    $setup.commentData[0].Comment.IdeaId,
                    item.Reply[0]
                      ? item.Reply[0].Comment.RootCommId
                      : item.Comment.CommId,
                    item.Comment.Content
                  )
                  )
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("submitLower")), 1)
                          ]),
                          _: 2
                        }, 1032, ["disabled", "onClick"]),
                        _createVNode(_component_el_button, {
                          class: "cancel-btn",
                          color: "white\n      ",
                          onClick: $setup.handleCancelClick
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("cancel")), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]))
        }), 128)),
        ($setup.commentData.length == 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_38, _toDisplayString(_ctx.$t("noCommentData")), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
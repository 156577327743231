import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "banner-container" }
const _hoisted_4 = { class: "rectangle" }
const _hoisted_5 = { class: "rectangle-left" }
const _hoisted_6 = { class: "rectangle-right" }
const _hoisted_7 = { class: "banner-text-container" }
const _hoisted_8 = { class: "banner-title" }
const _hoisted_9 = { class: "main-container" }
const _hoisted_10 = {
  class: "card-container",
  "infinite-scroll-disabled": "loadMoreIdeaLoading"
}
const _hoisted_11 = { class: "card-sub-container" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "title-text" }
const _hoisted_14 = { class: "summary-text" }
const _hoisted_15 = ["src"]
const _hoisted_16 = { class: "info-row" }
const _hoisted_17 = { class: "info-left" }
const _hoisted_18 = { style: {"margin-left":"4px"} }
const _hoisted_19 = { class: "card-sub-container" }
const _hoisted_20 = ["onClick"]
const _hoisted_21 = { class: "title-text" }
const _hoisted_22 = { class: "summary-text" }
const _hoisted_23 = ["src"]
const _hoisted_24 = { class: "info-row" }
const _hoisted_25 = { class: "info-left" }
const _hoisted_26 = { style: {"margin-left":"4px"} }
const _hoisted_27 = { class: "card-sub-container" }
const _hoisted_28 = ["onClick"]
const _hoisted_29 = { class: "title-text" }
const _hoisted_30 = { class: "summary-text" }
const _hoisted_31 = ["src"]
const _hoisted_32 = { class: "info-row" }
const _hoisted_33 = { class: "info-left" }
const _hoisted_34 = { style: {"margin-left":"4px"} }
const _hoisted_35 = { class: "card-sub-container" }
const _hoisted_36 = ["onClick"]
const _hoisted_37 = { class: "title-text" }
const _hoisted_38 = { class: "summary-text" }
const _hoisted_39 = ["src"]
const _hoisted_40 = { class: "info-row" }
const _hoisted_41 = { class: "info-left" }
const _hoisted_42 = { style: {"margin-left":"4px"} }
const _hoisted_43 = { class: "footer" }
const _hoisted_44 = { class: "footer_bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderBar = _resolveComponent("HeaderBar")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_IdeaFilter = _resolveComponent("IdeaFilter")!
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_PostActions = _resolveComponent("PostActions")!
  const _component_FooterBar = _resolveComponent("FooterBar")!
  const _component_el_backtop = _resolveComponent("el-backtop")!
  const _directive_infinite_scroll = _resolveDirective("infinite-scroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createVNode(_component_HeaderBar, { index: '/browse' })
    ]),
    _createElementVNode("section", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("showMyIdeas")), 1),
          _createVNode(_component_el_switch, {
            modelValue: $setup.isMyIdea,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.isMyIdea) = $event)),
            class: "showmyidea-switch",
            size: "large",
            onChange: $setup.handleSwitchChange
          }, null, 8, ["modelValue", "onChange"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_el_input, {
            modelValue: $setup.searchText,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.searchText) = $event)),
            class: "search-bar",
            size: "large",
            placeholder: _ctx.$t('searchAnything'),
            "prefix-icon": $setup.Search,
            onInput: $setup.handleTextInput,
            style: {"--el-color-primary":"#f36633"}
          }, null, 8, ["modelValue", "placeholder", "prefix-icon", "onInput"])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t("allIdeas")), 1)
      ])
    ]),
    _createElementVNode("section", _hoisted_9, [
      (_openBlock(), _createBlock(_component_IdeaFilter, {
        onFilterUpdate: $setup.handleFilterUpdate,
        initialStatusCode: $setup.initialStatusCode,
        style: {"min-width":"10rem"},
        key: $setup.IdeaFilterKey,
        id: "IdeaFilter"
      }, null, 8, ["onFilterUpdate", "initialStatusCode"])),
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.ideaList1, (card, i) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "card",
              key: i,
              onClick: ($event: any) => ($setup.navToDetail(card.Idea.IdeaId))
            }, [
              _createElementVNode("div", {
                class: "type-text",
                style: _normalizeStyle(`color: ${$setup.getStatusColor(card.Idea.Status)}`)
              }, [
                _createElementVNode("span", null, _toDisplayString($setup.getStatusText(card.Idea.Status)), 1)
              ], 4),
              _createElementVNode("div", _hoisted_13, _toDisplayString(card.Idea.Title), 1),
              _createElementVNode("div", _hoisted_14, _toDisplayString($setup.removeHtmlTags(card.Idea.Description)), 1),
              (card.Idea.BannerImgUrl)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: card.Idea.BannerImgUrl + '?' + $setup.getSasTokenFromCache(),
                    style: {"width":"100%","padding-top":"12px"}
                  }, null, 8, _hoisted_15))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, [
                  _createVNode(_component_el_avatar, {
                    src: 'data:image/jpeg;base64,' + card.User.Avatar,
                    size: 24
                  }, null, 8, ["src"]),
                  _createElementVNode("span", _hoisted_18, _toDisplayString(card.User.UserName), 1)
                ]),
                _createVNode(_component_PostActions, {
                  card: card,
                  onOperationCountChange: $setup.operationCountChange
                }, null, 8, ["card", "onOperationCountChange"])
              ])
            ], 8, _hoisted_12))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_19, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.ideaList2, (card, i) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "card",
              key: i,
              onClick: ($event: any) => ($setup.navToDetail(card.Idea.IdeaId))
            }, [
              _createElementVNode("div", {
                class: "type-text",
                style: _normalizeStyle(`color: ${$setup.getStatusColor(card.Idea.Status)}`)
              }, [
                _createElementVNode("span", null, _toDisplayString($setup.getStatusText(card.Idea.Status)), 1)
              ], 4),
              _createElementVNode("div", _hoisted_21, _toDisplayString(card.Idea.Title), 1),
              _createElementVNode("div", _hoisted_22, _toDisplayString($setup.removeHtmlTags(card.Idea.Description)), 1),
              (card.Idea.BannerImgUrl)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: card.Idea.BannerImgUrl + '?' + $setup.getSasTokenFromCache(),
                    style: {"width":"100%","padding-top":"12px"}
                  }, null, 8, _hoisted_23))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("div", _hoisted_25, [
                  _createVNode(_component_el_avatar, {
                    src: 'data:image/jpeg;base64,' + card.User.Avatar,
                    size: 24
                  }, null, 8, ["src"]),
                  _createElementVNode("span", _hoisted_26, _toDisplayString(card.User.UserName), 1)
                ]),
                _createVNode(_component_PostActions, {
                  card: card,
                  onOperationCountChange: $setup.operationCountChange
                }, null, 8, ["card", "onOperationCountChange"])
              ])
            ], 8, _hoisted_20))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_27, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.ideaList3, (card, i) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "card",
              key: i,
              onClick: ($event: any) => ($setup.navToDetail(card.Idea.IdeaId))
            }, [
              _createElementVNode("div", {
                class: "type-text",
                style: _normalizeStyle(`color: ${$setup.getStatusColor(card.Idea.Status)}`)
              }, [
                _createElementVNode("span", null, _toDisplayString($setup.getStatusText(card.Idea.Status)), 1)
              ], 4),
              _createElementVNode("div", _hoisted_29, _toDisplayString(card.Idea.Title), 1),
              _createElementVNode("div", _hoisted_30, _toDisplayString($setup.removeHtmlTags(card.Idea.Description)), 1),
              (card.Idea.BannerImgUrl)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: card.Idea.BannerImgUrl + '?' + $setup.getSasTokenFromCache(),
                    style: {"width":"100%","padding-top":"12px"}
                  }, null, 8, _hoisted_31))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_32, [
                _createElementVNode("div", _hoisted_33, [
                  _createVNode(_component_el_avatar, {
                    src: 'data:image/jpeg;base64,' + card.User.Avatar,
                    size: 24
                  }, null, 8, ["src"]),
                  _createElementVNode("span", _hoisted_34, _toDisplayString(card.User.UserName), 1)
                ]),
                _createVNode(_component_PostActions, {
                  card: card,
                  onOperationCountChange: $setup.operationCountChange
                }, null, 8, ["card", "onOperationCountChange"])
              ])
            ], 8, _hoisted_28))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_35, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.ideaList4, (card, i) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "card",
              key: i,
              onClick: ($event: any) => ($setup.navToDetail(card.Idea.IdeaId))
            }, [
              _createElementVNode("div", {
                class: "type-text",
                style: _normalizeStyle(`color: ${$setup.getStatusColor(card.Idea.Status)}`)
              }, [
                _createElementVNode("span", null, _toDisplayString($setup.getStatusText(card.Idea.Status)), 1)
              ], 4),
              _createElementVNode("div", _hoisted_37, _toDisplayString(card.Idea.Title), 1),
              _createElementVNode("div", _hoisted_38, _toDisplayString($setup.removeHtmlTags(card.Idea.Description)), 1),
              (card.Idea.BannerImgUrl)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: card.Idea.BannerImgUrl + '?' + $setup.getSasTokenFromCache(),
                    style: {"width":"100%","padding-top":"12px"}
                  }, null, 8, _hoisted_39))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_40, [
                _createElementVNode("div", _hoisted_41, [
                  _createVNode(_component_el_avatar, {
                    src: 'data:image/jpeg;base64,' + card.User.Avatar,
                    size: 24
                  }, null, 8, ["src"]),
                  _createElementVNode("span", _hoisted_42, _toDisplayString(card.User.UserName), 1)
                ]),
                _createVNode(_component_PostActions, {
                  card: card,
                  onOperationCountChange: $setup.operationCountChange
                }, null, 8, ["card", "onOperationCountChange"])
              ])
            ], 8, _hoisted_36))
          }), 128))
        ])
      ])), [
        [_directive_infinite_scroll, $setup.loadMoreIdea]
      ])
    ]),
    _createElementVNode("section", _hoisted_43, _toDisplayString(_ctx.$t("aboutGSKMsg")), 1),
    _createElementVNode("section", _hoisted_44, [
      _createVNode(_component_FooterBar, { activeItem: 3 })
    ]),
    _createVNode(_component_el_backtop, {
      right: 40,
      bottom: 100
    })
  ]))
}
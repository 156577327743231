export default {
  loginBtn: "GSKアカウントでログイン",
  welcomeMainMsg: "アイデアボックスへようこそ！",
  welcomeSubMsg:
    "こちらは、あなたのアイデアを共有し、周囲の皆さんと協力して、アイデアを形にしていく場所です。アイデアの投稿、進捗状況の確認、完了済みアイデアの閲覧が可能です。",
  submitIdea: "アイデアを投稿する",
  andThen: "",
  andThenMsg: "他の方のアイデアを見て、コメントをしたり、いいね！をしたりすることもできます。",
  new: "New",
  discussion: "ディスカッション",
  reviewing: "Reviewing",
  developing: "Developing",
  completed: "Completed",
  closed: "Closed",
  building: "建設中",
  playground: "プレイグラウンド",
  pilot: "パイロット",
  pilotLower: "パイロット",
  workInProgress: "進行中の作業",
  updates: "Updates",
  viewMore: "詳細を見る",
  aboutGSK: "GSKについて",
  aboutGSKMsg: "GSK plc. 全著作権所有",
  idea: "アイデア",
  submitYourIdea: "アイデアを投稿する",
  title: "タイトル",
  imgCondition:
    "アップロード可能なファイル形式：JPG、JPEG、PNG、GIF（5MB以内） <br> 推奨サイズ：320x240ピクセル以上",
  chooseImg: "画像を選択する",
  chooseImgAgain: "画像を再選択する",
  description: "アイデアの内容",
  attachments: "添付ファイル",
  drag: "ファイルをドラッグ＆ドロップ、またはローカルから選択",
  mobileUploadMsg: "モバイルデバイスからアップロード",
  cancel: "キャンセル",
  home: "ホーム",
  submit: "アイデアを投稿する",
  browse: "アイデア一覧",
  allIdeas: "すべてのアイデア",
  showMyIdeas: "自分のアイデアを表示",
  searchAnything: "検索",
  operation: "リアクション",
  status: "ステータス",
  department: "部門",
  owner: "所有者",
  clear: "クリア",
  collected: "収集済み",
  supported: "サポート済み",
  commented: "コメント済み",
  comments: "コメント",
  submitted: "提出済み",
  followIdea: "アイデアをフォローする",
  unfollowIdea: "アイデアのフォローを解除する",
  users: "ユーザー",
  yourSuggestions: "ご意見",
  yourSuggestionsMsg: "ご意見をお聞かせください",
  viewProfile: "プロフィールを表示",
  job: "職種",
  ideas: "アイデア",
  ideasUpper: "アイデア",
  noSubmissions: "提出されたアイデアはありません。",
  myIdeas: "私のアイデア",
  myComments: "私のコメント",
  firstName: "名",
  lastName: "姓",
  email: "メールアドレス",
  oldPassword: "旧パスワード",
  newPassword: "新しいパスワード",
  confirmPassword: "パスワードを確認",
  passwordMsg: "新しいパスワードが一致しません。",
  userPool: "ユーザープール",
  ideasManagement: "アイデア管理",
  createTime: "投稿日時",
  lastUpdate: "最終更新",
  detail: "詳細",
  action: "削除",
  searchIdeaName: "アイデア名を検索...",
  commentsDetail: "コメントの詳細",
  reply: "返信",
  replyMsg: "ここにコメントを入力してください...",
  onlyAdmin: "管理者のみ",
  manageAuthority: "権限の管理",
  submitLower: "提出",
  edit: "編集",
  delete: "削除",
  confirm: "確認",
  applyMsg:
    "管理者が申請を確認したら、メールで通知されます。<br />管理者権限の申請を続行しますか？",
  adminUser: "管理者ユーザー",
  playgroundLower: "プレイグラウンド",
  followIdeaMsg: "アイデアをフォローし、週次の進捗報告をメールで受け取る。",
  userName: "ユーザー名",
  lastLoginTime: "最終ログイン時刻",
  creativeIdeas: "クリエイティブなアイデア",
  approve: "承認",
  reject: "拒否",
  logout: "ログアウト",
  noCommentData: "コメントデータなし",
  justNow: "たった今",
  minute: "分",
  minutes: "分",
  hour: "時間",
  hours: "時間",
  day: "日",
  days: "日",
  week: "週間",
  weeks: "週間",
  month: "月",
  months: "月",
  year: "年",
  years: "年",
  ago: "前",
  CreatedBy: "作成者",
  Collects: "コレクション",
  Likes: "いいね",
  reset: "リセット",
  statistics: "統計",
  allTime: "全期間",
  withinayear: "1年以内",
  withinsixmonths: "6ヶ月以内",
  sum: "合計",
  exportExcel: "Excelエクスポート",
  warning: "警告",
  Warning: "警告",
  warningmsg: "この操作は永久的な削除を引き起こします。続行しますか？",
  deletesuccess: "削除成功",
  deletecancel: "削除キャンセル",
  submit_warning: "個人情報やGSKの機密データのアップロードは、お控えください。",
  category: "カテゴリー",
  tag: "タグ",
  categoryMgt: "カテゴリー管理",
  tagMgt: "タグ管理",
  addCategory: "カテゴリーを追加",
  addTag: "タグを追加",
  categoryName: "カテゴリー名",
  inputMsg: "入力してください",
  categoryExist: "カテゴリーはすでに存在します",
  tagExist: "タグはすでに存在します",
  disbale: "無効",
  disableSuccess: "無効化完了",
  disableMsg: "このカテゴリーを無効にしますか？",
  enableMsg: "このカテゴリーを有効にしますか？",
  tagName: "タグ名",
  selectMsg: "選択してください",
  loading: "読み込み中...",
  addSuccess: "追加完了",
  tagLimit: "タグの制限に達しました",
  submitFailed: "送信に失敗しました。後でもう一度お試しください",
  assignTracker: "トラッカーを割り当てる",
  assignTrackerSuccess: "トラッカーの割り当てが完了しました",
  submitSuccess: "送信完了",
  trackedBy: "トラッカー",
  languageMsg: "どの言語を表示しますか？"
};